(function () {
  angular.module('7Shop.Core').constant('ApplicationStates', {
    shopApp: {
      'app.home.touchdetection': {
        url: '/touchdetection',
        template: {
          default: '<div class="max-size"><h1>Desktop layout</h1><br><h3 '
          + 'is-desktop>Desktop directive</h3><h3 is-touch>Touch directive</h3></div>',
          mobile: '<div class="max-size"><h1>Touch layout</h1><br><h3'
          + ' is-desktop>Deskotp directive</h3><h3 is-touch>Touch directive</h3></div>'
        },
        resolve: {
          loaded: function ($log) {
            $log.info('[NGS.Touch] app.home.touchdetection loaded');
            return true;
          }
        },
        data: {
          test: true
        }
      }
    }
  });
})();

(function () {
  angular
    .module('7Shop.Common')
    .directive('triggerFormatters', function renderOnBlur() {
      return {
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {
          element.on('blur', () => runFormatters(modelCtrl));

          /**
           * This function is a copy of the internal formatter running code
           * and it runs the formatter on the model value
           * https://github.com/angular/angular.js/issues/3407#issue-17469647
           * @param {*} modelCtrl
           */
          function runFormatters(ctrl) {
            var modelValue = ctrl.$modelValue;

            var formatters = ctrl.$formatters;
            var idx = formatters.length;

            var viewValue = modelValue;

            /* eslint-disable no-plusplus */
            while (idx--) {
              viewValue = formatters[idx](viewValue);
            }

            if (ctrl.$viewValue !== viewValue) {
              /* eslint-disable no-multi-assign */
              ctrl.$viewValue = ctrl.$$lastCommittedViewValue = viewValue;
              ctrl.$render();

              ctrl.$$runValidators(modelValue, viewValue, angular.noop);
            }
          }
        }
      };
    });
})();

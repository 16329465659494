export default function randomNumService() {
  return {
    /**
         * returns an array of random numbers
         * @qty - array length needed
         * @min - minimum value of random numbers
         * @max - this value is excluded from the calculation
         */
    getNumbers: function (qty, min, max) {
      var randomNumbers = [];
      var num = 0;
      // eslint-disable-next-line
      ++max;

      while (randomNumbers.length < qty) {
        num = Math.floor(Math.random() * (max - min) + min);

        if (randomNumbers.indexOf(num) < 0) {
          randomNumbers.push(num);
        }
      }

      randomNumbers.sort(function (a, b) {
        return a - b;
      });

      return randomNumbers;
    }
  };
}

/* eslint-disable import/no-unresolved */
import { each } from 'underscore';

const SHOP_MODULES = {
  list: {
    app: {
      ng: {
        moduleName: '7Shop'
      },
      dest: 'dist/app.js'
    },
    home: {
      lazyLoad: {
        dest: 'app/src/modules/home/home.js',
        load: () => import(/* webpackChunkName: "src.modules.home" */ 'pages.home')
      },
      ng: {
        moduleName: 'shopApp'
      },
      dest: 'dist/app/src/modules/home/home.js'
    },
    lucky_six: {
      ng: {
        moduleName: 'shopApp.nabLuckySixDisplay'
      },
      dest: 'dist/app/src/games/lucky_six.js'
    },
    greyhound_races: {
      ng: {
        moduleName: 'shopApp.nabGreyhoundRaces'
      },
      dest: 'dist/app/src/games/greyhound_races.js'
    },
    horse_races: {
      ng: {
        moduleName: 'shopApp.nabHorseRaces'
      },
      dest: 'dist/app/src/games/horse_races.js'
    },
    lucky_x: {
      ng: {
        moduleName: 'shopApp.nabLuckyXDisplay'
      },
      dest: 'dist/app/src/games/lucky_x.js'
    },
    virtual_motorcycle_speedway: {
      ng: {
        moduleName: 'shopApp.SevenVMS'
      },
      dest: 'dist/app/src/games/virtual_motorcycle_speedway.js'
    },
    slot_car_races: {
      ng: {
        moduleName: 'shopApp.SevenSCR'
      },
      dest: 'dist/app/src/games/slot_car_races.js'
    },
    '7Shop.Partials': {
      ng: {
        moduleName: '7Shop.Partials'
      },
      lazyLoad: {
        dest: 'app/src/partials/partials.js'
      },
      dest: 'dist/app/src/partials/partials.js'
    },
    PrintOptions: {
      ng: {
        moduleName: '7Shop.PrintOptions'
      },
      lazyLoad: {
        dest: 'app/src/printOptions/printOptions.js',
        load: () => import(/* webpackChunkName: "src.printOptions" */'PrintOptions')
      },
      dest: 'dist/app/src/printOptions/printOptions.js'
    },
    '7Shop.Sentry': {
      lazyLoad: {
        dest: 'app/src/sentry/sentry.js'
      },
      dest: 'dist/app/src/sentry/sentry.js'
    },
    '7Shop.Touch': {
      ng: {
        moduleName: '7Shop.Touch'
      },
      lazyLoad: {
        dest: 'app/src/modules/touch.js',
        load: () => import(/* webpackChunkName: "src.modules.touch" */'7Shop.Touch')
      },
      dest: 'dist/app/src/modules/touch.js'
    }
  },

  filterLazyLoadModules: function (position) {
    var result = [];

    each(this.list, function (module, key) {
      if (module.lazyLoad && module.lazyLoad.position === position) {
        module.id = key;
        result.push(module);
      }
    });

    return result;
  },

  getModules: function () {
    each(this.list, function (module, key) {
      module.id = key;
    });

    return this.list;
  },

  getModule: function (moduleName) {
    return this.list[moduleName];
  }
};

export default SHOP_MODULES;

(function () {
  angular.module('7Shop.Bootstrap')
    .factory('sevenInterceptor', sevenInterceptor);

  function sevenInterceptor(
    $q,
    $injector,
    $rootScope,
    LocalData,
    NabENV,
    SevenRoutes,
    ShopEnvironment,
    CloudflareService
  ) {
    function isValidUrl(url) {
      var whiteList = [
        CloudflareService.generateCloudflareUrl(NabENV.api.ngs.london),
        CloudflareService.generateCloudflareUrl(NabENV.api.ngs.ro),
        CloudflareService.generateCloudflareUrl(SevenRoutes.mainUrl),
        'https://services-staging.7platform.com',
        'https://services.7platform.com',
        'https://services-de.7platform.com',
        'https://services-ro.7platform.com',
        'https://services-ro2.7platform.com',
        'https://services-rs.7platform.com',
        'https://services-rs2.7platform.com',
        'https://menhir.staging.gb.nsoftcdn.com',
        'https://menhir.gb.nsoftcdn.com',
        'https://menhir.de.nsoftcdn.com',
        'https://menhir.ro.nsoftcdn.com',
        'https://menhir.ro-2.nsoftcdn.com',
        'https://menhir.rs.nsoftcdn.com',
        'https://menhir.rs-2.nsoftcdn.com'
      ];

      var whiteListedUrl;
      var i;

      for (i = 0; i < whiteList.length; i += 1) {
        whiteListedUrl = whiteList[i];
        if (url.substring(0, whiteListedUrl.length) === whiteListedUrl) {
          return true;
        }
      }

      return false;
    }

    return {
      request: function (request) {
        var companyService;
        // eslint-disable-next-line no-unused-vars
        var userService = $injector.get('userService');
        var lang = ShopEnvironment.getLanguage();
        request.url = CloudflareService.generateCloudflareUrl(request.url);

        if (isValidUrl(request.url)) {
          companyService = $injector.get('companyService');
          if (Object.keys(LocalData).length) {
            request.headers['HTTP-X-NAB-COMPANY-ID'] = LocalData.betshop.companyId;
            request.headers['HTTP-X-NAB-DEVICE-UUID'] = LocalData.device.uuid;
            request.headers['HTTP-X-NAB-DP'] = 'Retail';
            request.headers['HTTP-X-NAB-BETSHOP-ID'] = LocalData.betshop.betshopId;
            request.headers['X-SEVEN-DEVICE-TOKEN'] = LocalData.device.token;
          }

          if (lang) {
            request.headers['SEVEN-LOCALE'] = lang;
          }

          request.headers['X-NSFT-SEVEN-COMPANY-UUID'] = companyService.getUuid();
        }

        return request;
      },

      responseError: function (rejection) {
        var userService = $injector.get('userService');
        if (rejection.status === 401 && userService.state.loggedIn) {
          $rootScope.$emit('$http:unauthorizedRequest', rejection.data);
        }

        return $q.reject(rejection);
      }
    };
  }
})();

(function () {
  angular.module('7Shop.Core')
    .factory('switchView', switchView);

  /**
   * @constructor switchView
   */
  function switchView(
    $state,
    $rootScope,
    SevenGamesSvc
  ) {
    function activateUiRoute(service) {
      service.productId = service.id;
      $rootScope.$emit(
        '7S:Slave.Shown',
        service
      );
    }

    return {

      /**
       * Activate product and its services
       *
       * @memberOf switchView
       * @param {SevenGameSvc | string} service
       */
      selectService: function (service) {
        if (angular.isString(service)) {
          service = SevenGamesSvc.getGame(service);
        }
        // cleaning all selected bet types and inputs on iframes
        $rootScope.$broadcast('KeyPressed', '+');
        SevenGamesSvc.setActiveGame(service.id);
        $rootScope.$emit('7S:Header:UpdateGames', service);

        activateUiRoute(service);
      },

      /**
       * Check is product view active
       *
       * @memberOf switchView
       * @param {String} productId
       * @return {boolean}
       */
      isServiceActive: function (productId) {
        return $state.is('app.home.games.' + productId);
      },

      /**
       * @memberOf switchView
       */
      back: function () {
        var game = SevenGamesSvc.activeGame;
        if (game) {
          this.selectService(game.id);
        }
      }

    };
  }
})();

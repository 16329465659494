import angular from 'angular';
import capitalize from './capitalizeFilter';
import autoSelectText from './autoSelectText';
import arrayService from './createArrayService';
import nabTrans from './transService';
import modalSvc from './modal/modalService';
import modalSvcConfig from './modal/modalServiceProvider';
import flashButton from './btnFlashService';
import systemCombinationsService from './systemCombinationsService';
import payinAmountService from './payinAmountService';
import randomNumService from './randomService';

// this is an old module that is deprecated
// and all parts should moved to 7Shop.Common
const moduleName = angular.module('ngLib', [])
  .filter('capitalize', capitalize)
  .directive('autoSelectText', autoSelectText)
  .service('arrayService', arrayService)
  .service('nabTrans', nabTrans)
  .service('flashButton', flashButton)
  .service('systemCombinationsService', systemCombinationsService)
  .service('payinAmountService', payinAmountService)
  .service('randomNumService', randomNumService)
  .factory('modalSvc', modalSvc)
  .provider('modalSvcConfig', modalSvcConfig)
  .name;

export default moduleName;

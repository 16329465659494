import { AsyncSeriesWaterfallHook, AsyncSeriesHook } from 'tapable';

(function () {
  angular.module('7Shop.Common')
    .service('HooksManager', HooksManager);

  /**
     * @class HooksManager
     * @memberOf module:"7Shop.Common"
     */
  function HooksManager($q, $log) {
    this.hooks = {
      BetslipBeforeBetAdd: new AsyncSeriesWaterfallHook(['productId']),
      BeforeBetslipPayin: new AsyncSeriesWaterfallHook([
        'bets',
        'stake',
        'perBetValue',
        'updatedBets',
        'betslipId'
      ]),
      BetslipOnInputBlur: new AsyncSeriesWaterfallHook(['bets', 'stake']),
      OnBetStakeChange: new AsyncSeriesWaterfallHook(['bet', 'stakePerBet']),
      'DirectTransferController.BeforeSearchPlayer': new AsyncSeriesHook(),
      'DirectTransferController.BeforeSubmitDeposit': new AsyncSeriesHook(),
      'WithdrawalProcessing.BeforeGetWithdrawalInfo': new AsyncSeriesHook(),
      BeforeVoucherCreate: new AsyncSeriesWaterfallHook(['voucher']),
      BeforeVoucherCancel: new AsyncSeriesWaterfallHook(['voucher']),
      BeforeVoucherPayout: new AsyncSeriesWaterfallHook(['voucher']),
      BeforeTicketPayin: new AsyncSeriesWaterfallHook(['params', 'product']),
      BeforeTicketCancel: new AsyncSeriesWaterfallHook(['ticket']),
      BeforeTicketPayout: new AsyncSeriesWaterfallHook(['ticket']),
      'RomTax:GetPlayerData': new AsyncSeriesWaterfallHook(['cnp']),
      'RomTax:AddFormData': new AsyncSeriesWaterfallHook(['params']),
      AfterPayoutTicket: new AsyncSeriesWaterfallHook(['ticketId']),
      BeforeTicketPreview: new AsyncSeriesWaterfallHook(['isTicketWon']),
      BeforeLogout: new AsyncSeriesWaterfallHook(['logout'])
    };

    this.run = function (hookName, params) {
      if (!this.hooks[hookName]) {
        $log.error(`[NGS.Common] Hook ${hookName} doesn't exist.`);
        return $q.reject({
          message: `[NGS.Common] Hook ${hookName} doesn't exist.`,
          code: 'S_HOOK_NOT_FOUND'
        });
      }

      return this.hooks[hookName].promise(params);
    };

    this.getHook = function (hookName) {
      return this.hooks[hookName];
    };
  }
})();
